import React from 'react';
import styles from "../styles.module.css";

/*
Used for color selection based off inputs
*/
const ColorComponent = ({ passData, returnClick, fenceMaterial}) => {

  let colorContent;
  let clickReturn = "fenceColor";

  switch (fenceMaterial) 
  {
    case "Aluminum":
      colorContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <button className={styles.Button2} onClick={() => passData("White")}>White</button>
                <button className={styles.Button2} onClick={() => passData("Black")}>Black</button>
                <button className={styles.Button2} onClick={() => passData("Bronze")}>Bronze</button>
                <button className={styles.Button2} onClick={() => passData("Gray")}>Gray</button>
            </div>
        </div>
      );
      break;
    case "Standard Vinyl":
      colorContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <button className={styles.Button2} onClick={() => passData("White")}>White</button>
                <button className={styles.Button2} onClick={() => passData("Adobe")}>Adobe</button>
                <button className={styles.Button2} onClick={() => passData("Beige")}>Beige</button>
                <button className={styles.Button2} onClick={() => passData("Gray")}>Gray</button>
            </div>
        </div>
      );
      break;

    //Covers Deer & Chainlink Fence
    case "Steel":
      passData("Black");
      break;

    case "Solid Vinyl":
      colorContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <button className={styles.Button2} onClick={() => passData("White")}>White</button>
                <button className={styles.Button2} onClick={() => passData("Dark Green")}>Dark Green</button>
                <button className={styles.Button2} onClick={() => passData("Black")}>Black</button>
                <button className={styles.Button2} onClick={() => passData("Dark Gray")}>Dark Gray</button>
            </div>
        </div>
      );
      break;

    //Covers Wood
    default:
      colorContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <button className={styles.Button2} onClick={() => passData("Natural")}>Natural</button>
                <button className={styles.Button2} onClick={() => passData("White")}>White</button>
                <button className={styles.Button2} onClick={() => passData("Dark Green")}>Dark Green</button>
                <button className={styles.Button2} onClick={() => passData("Black")}>Black</button>
                <button className={styles.Button2} onClick={() => passData("Dark Gray")}>Dark Gray</button>
            </div>
        </div>
      );
  }

  return (
    <div style={{ paddingBottom: "80px" }}>
      <div id="fenceTypes">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: "30px" }}>
            <h1 style={{color: 'maroon'}}>Please Select a Fence Color!</h1>
            <div style={{ textAlign: "center", display: 'flex', justifyContent: 'flex-start' }}>
                <button className={styles.Button} onClick={() => returnClick(clickReturn)}>Return</button>
            </div>
        </div>
        {colorContent}
      </div>
    </div>
  );
}

export default ColorComponent;
