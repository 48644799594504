import React from 'react';
import styles from "../styles.module.css";
/*
Takes Material input 
*/
const MaterialComponent = ({ passData, returnClick, fenceType}) => {

  let materialContent;
  let clickReturn = "fenceMaterial";

  switch (fenceType) 
  {
    case "Aluminum":
        passData("Aluminum");
        break;

    case "Deer":
        passData("Steel");
        break;

    case "Chainlink":
        passData("Steel");
        break;

    case "Post & Rail"://Same case for remaining options; privacy, picket.
      clickReturn="skipHeight";
      materialContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <button className={styles.Button2} onClick={() => passData("Cedar Wood")}>Cedar Wood</button>
                <button className={styles.Button2} onClick={() => passData("Locust Wood")}>Locust Wood</button>
                <button className={styles.Button2} onClick={() => passData("Standard Vinyl")}>Standard Vinyl</button>
                <button className={styles.Button2} onClick={() => passData("Solid Vinyl")}>Solid Vinyl</button>
            </div>
        </div>
        );
        break;

    default://Same case for remaining vinyl options; privacy, picket.
      materialContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <button className={styles.Button2} onClick={() => passData("Standard Vinyl")}>Standard Vinyl</button>
                <button className={styles.Button2} onClick={() => passData("Solid Vinyl")}>Solid Vinyl</button>
            </div>
        </div>
        );
  }

  return (
    <div style={{ paddingBottom: "80px" }}>
      <div id="fenceTypes">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: "30px" }}>
            <h1 style={{color: 'maroon'}}>Please Select a Fence Material!</h1>
            <div style={{ textAlign: "center", display: 'flex', justifyContent: 'flex-start' }}>
                <button className={styles.Button} onClick={() => returnClick(clickReturn)}>Return</button>
            </div>
        </div>
        {materialContent}
      </div>
    </div>
  );
}

export default MaterialComponent;
