import React from 'react';
import styles from "../styles.module.css";
/*
Taking height inputs. 
*/
const HeightComponent = ({ setMesh, bypassMat, passData, returnClick, fenceType, fenceStyle}) => {

  let heightContent;
  let clickReturn = "fenceHeight";

  switch (fenceType) 
  {
    case "Aluminum":
      heightContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <button className={styles.Button2} onClick={() => {passData("3'");  bypassMat("Aluminum");}}>3 Feet</button>
                <button className={styles.Button2} onClick={() => {passData("4'");  bypassMat("Aluminum");}}>4 Feet</button>
                <button className={styles.Button2} onClick={() => {passData('54"'); bypassMat("Aluminum");}}>Pool Code</button>
                <button className={styles.Button2} onClick={() => {passData("5'");  bypassMat("Aluminum");}}>5 Feet</button>
                <button className={styles.Button2} onClick={() => {passData("6'");  bypassMat("Aluminum");}}>6 Feet</button>
            </div>
        </div>
      );
      break;

    case "Cedar Privacy":
      heightContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <button className={styles.Button2} onClick={() => {passData("4'"); bypassMat("Cedar");}}>4 Feet</button>
                <button className={styles.Button2} onClick={() => {passData("5'"); bypassMat("Cedar");}}>5 Feet</button>
                <button className={styles.Button2} onClick={() => {passData("6'"); bypassMat("Cedar");}}>6 Feet</button>
                <button className={styles.Button2} onClick={() => {passData("7'"); bypassMat("Cedar");}}>7 Feet</button>
                <button className={styles.Button2} onClick={() => {passData("8'"); bypassMat("Cedar");}}>8 Feet</button>
            </div>
        </div>
      );
      break;
      
    case "Vinyl Privacy":
      heightContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <button className={styles.Button2} onClick={() => passData("3'")}>3 Feet</button>
                <button className={styles.Button2} onClick={() => passData("4'")}>4 Feet</button>
                <button className={styles.Button2} onClick={() => passData("5'")}>5 Feet</button>
                <button className={styles.Button2} onClick={() => passData("6'")}>6 Feet</button>
                <button className={styles.Button2} onClick={() => passData("7'")}>7 Feet</button>
                <button className={styles.Button2} onClick={() => passData("8'")}>8 Feet</button>
            </div>
        </div>
      );
      break;

    case "Cedar Picket":
      heightContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <button className={styles.Button2} onClick={() => {passData("3'"); bypassMat("Cedar");}}>3 Feet</button>
                <button className={styles.Button2} onClick={() => {passData("4'"); bypassMat("Cedar");}}>4 Feet</button>
                <button className={styles.Button2} onClick={() => {passData("5'"); bypassMat("Cedar");}}>5 Feet</button>
                <button className={styles.Button2} onClick={() => {passData("6'"); bypassMat("Cedar");}}>6 Feet</button>
            </div>
        </div>
      );
      break;

    case "Vinyl Picket":
      heightContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <button className={styles.Button2} onClick={() => passData("3'")}>3 Feet</button>
                <button className={styles.Button2} onClick={() => passData("4'")}>4 Feet</button>
                <button className={styles.Button2} onClick={() => passData("5'")}>5 Feet</button>
                <button className={styles.Button2} onClick={() => passData("6'")}>6 Feet</button>
            </div>
        </div>
      );
      break;
    
    case "Deer":
      clickReturn = "skipStyle";
      heightContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <button className={styles.Button2} onClick={() => passData("6'")}>6 Feet</button>
                <button className={styles.Button2} onClick={() => passData("7'")}>7 Feet</button>
            </div>
        </div>
      );
      break;

    case "Chainlink":
      clickReturn = "skipStyle";
      heightContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <button className={styles.Button2} onClick={() => passData("3'")}>3 Feet</button>
                <button className={styles.Button2} onClick={() => passData("4'")}>4 Feet</button>
                <button className={styles.Button2} onClick={() => passData("5'")}>5 Feet</button>
                <button className={styles.Button2} onClick={() => passData("6'")}>6 Feet</button>
                <button className={styles.Button2} onClick={() => passData("8'")}>8 Feet</button>
            </div>
        </div>
      );
      break;

    default:
      heightContent = (
        
        <div>
          {/* Default segment content */}
        </div>
      );
    }

  switch(fenceStyle)
  {
    //In the case of p&r fence, we need to update fenceStyle. Or. Just assume 3' = 3 rail.
    case "Cedar Flat":
      heightContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => {passData("3'"); bypassMat("Cedar"); setMesh(null)}}>
                    <h1>2 Rail ~ 3' High</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Flat-2-Rail-Icon.webp"} alt="Cedar-Flat-2-Rail-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => {passData("4'"); bypassMat("Cedar"); setMesh(null)}}>
                    <h1>3 Rail ~ 4' High</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Flat-3-Rail-Icon.webp"} alt="Cedar-Flat-3-Rail-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => {passData("5'"); bypassMat("Cedar"); setMesh(null)}}>
                    <h1>4 Rail ~ 5' High</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Flat-4-Rail-Icon.webp"} alt="Cedar-Flat-4-Rail-Icon" />
                </div>
            </div>
        </div>
      );
      break;

    case "Vinyl Flat":
      heightContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => {passData("3'"); setMesh(null)}}>
                    <h1>2 Rail ~ 3' High</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Vinyl-Flat-2-Rail-Icon.webp"} alt="Vinyl-Flat-2-Rail-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => {passData("4'"); setMesh(null)}}>
                    <h1>3 Rail ~ 4' High</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Vinyl-Flat-3-Rail-Icon-1.webp"} alt="Vinyl-Flat-3-Rail-Icon-1" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => {passData("5'"); setMesh(null)}}>
                    <h1>4 Rail ~ 5' High</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Vinyl-Flat-4-Rail-Icon.webp"} alt="Vinyl-Flat-4-Rail-Icon" />
                </div>
            </div>
        </div>
      );
      break;
    
    case "Cedar Diamond":
      heightContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => {passData("3'"); bypassMat("Cedar"); setMesh(null)}}>
                    <h1>2 Rail ~ 3' High</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Diamond-Post-Rail-2-Rail-Icon.webp"} alt="Cedar-Diamond-Post-Rail-2-Rail-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => {passData("4'"); bypassMat("Cedar"); setMesh(null)}}>
                    <h1>3 Rail ~ 4' High</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Diamond-Post-Rail-Icon.webp"} alt="Cedar-Diamond-Post-Rail-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => {passData("5'"); bypassMat("Cedar"); setMesh(null)}}>
                    <h1>4 Rail ~ 5' High</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Diamond-Post-Rail-4-Rail-Icon.webp"} alt="Cedar-Diamond-Post-Rail-4-Rail-Icon" />
                </div>
            </div>
        </div>
      );
      break;
    
    case "Vinyl Diamond":
    heightContent = (
      <div>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
              <div style={{textAlign: 'center'}} onClick={() => {passData("3'"); setMesh(null)}}>
                  <h1>2 Rail ~ 3' High</h1>
                  <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Vinyl-Diamond-Post-Rail-2-Rail.webp"} alt="Vinyl-Diamond-Post-Rail-2-Rail" />
              </div>
              <div style={{textAlign: 'center'}} onClick={() => {passData("4'"); setMesh(null)}}>
                  <h1>3 Rail ~ 4' High</h1>
                  <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Vinyl-Diamond-Post-Rail-3-Rail.webp"} alt="Vinyl-Diamond-Post-Rail-3-Rail" />
              </div>
              <div style={{textAlign: 'center'}} onClick={() => {passData("5'"); setMesh(null)}}>
                  <h1>4 Rail ~ 5' High</h1>
                  <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Vinyl-Diamond-Post-Rail-4-Rail.webp"} alt="Vinyl-Diamond-Post-Rail-4-Rail" />
              </div>
          </div>
      </div>
    );
    break;

    case "Cedar Round":
    heightContent = (
      <div>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
              <div style={{textAlign: 'center'}} onClick={() => {passData("3'"); bypassMat("Cedar"); setMesh(null)}}>
                  <h1>2 Rail ~ 3' High</h1>
                  <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Round-Post-Rail-2-Icon.webp"} alt="Cedar-Round-Post-Rail-2-Icon" />
              </div>
              <div style={{textAlign: 'center'}} onClick={() => {passData("4'"); bypassMat("Cedar"); setMesh(null)}}>
                  <h1>3 Rail ~ 4' High</h1>
                  <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Round-Post-Rail-3-Icon.webp"} alt="Cedar-Round-Post-Rail-3-Icon" />
              </div>
              <div style={{textAlign: 'center'}} onClick={() => {passData("5'"); bypassMat("Cedar"); setMesh(null)}}>
                  <h1>4 Rail ~ 5' High</h1>
                  <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Round-Post-Rail-4-Icon.webp"} alt="Cedar-Round-Post-Rail-4-Icon" />
              </div>
          </div>
      </div>
    );
    break;
    
    case "Rustic Split Rail":
    heightContent = (
      <div>
          <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
              <div style={{textAlign: 'center'}} onClick={() => {passData("3'"); bypassMat("Locust"); setMesh(null)}}>
                  <h1>2 Rail ~ 3' High</h1>
                  <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Locust-Rustic-Split-Rail-2-Icon.webp"} alt="Locust-Rustic-Split-Rail-2-Icon" />
              </div>
              <div style={{textAlign: 'center'}} onClick={() => {passData("4'"); bypassMat("Locust"); setMesh(null)}}>
                  <h1>3 Rail ~ 4' High</h1>
                  <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Locust-Rustic-Split-Rail-3-Icon.webp"} alt="Locust-Rustic-Split-Rail-3-Icon" />
              </div>
              <div style={{textAlign: 'center'}} onClick={() => {passData("5'"); bypassMat("Locust"); setMesh(null)}}>
                  <h1>4 Rail ~ 5' High</h1>
                  <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Locust-Rustic-Split-Rail-4-Icon.webp"} alt="Locust-Rustic-Split-Rail-4-Icon" />
              </div>
          </div>
      </div>
    );
    break;

    default:
      break;
  }

  return (
    <div style={{ paddingBottom: "80px" }}>
      <div id="fenceTypes">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: "30px" }}>
            <h1 style={{color: 'maroon'}}>Please Select a Fence Height!</h1>
            <div style={{ textAlign: "center", display: 'flex', justifyContent: 'flex-start' }}>
                <button className={styles.Button} onClick={() => returnClick(clickReturn)}>Return</button>
            </div>
        </div>
        {heightContent}
      </div>
    </div>
  );
}

export default HeightComponent;
