import React, { useState } from 'react';
import DefaultLayout from './defaultLayout';
import StyleComponent from './styleComponent';
import HeightComponent from './heightComponent';
import MaterialComponent from './materialComponent';
import ColorComponent from './colorComponent';
import styles from "../styles.module.css";

const FenceSelect = ({passData, fenceCount, returnFunction}) => {

    const [fenceType, setFenceType] = useState(null);
    const [fenceStyle, setFenceStyle] = useState(null);
    const [fenceHeight, setFenceHeight] = useState(null);
    const [fenceMaterial, setFenceMaterial] = useState(null);
    const [wireMesh, setWireMesh] = useState("blank");


    //Returns to map. 
    const goHome = () =>
    {
        returnFunction();
    }

    const submitFence = (fenceColor) =>
    {
        let fenceAttributes = { material: fenceMaterial, height: fenceHeight, color: fenceColor, style: fenceStyle, type: fenceType};

        if(fenceType === "Aluminum" || fenceType === "Cedar Privacy" || fenceType === "Cedar Picket")
        {
            fenceAttributes = { material: "", height: fenceHeight, color: fenceColor, style: fenceStyle, type: fenceType};
        }

        if(fenceType === "Vinyl Privacy")
        {
            if(fenceMaterial === "Standard Vinyl")
            {
                fenceAttributes = { material: fenceMaterial, height: fenceHeight, color: fenceColor, style: fenceStyle, type: "Privacy"};
            }
        }

        if(fenceType === "Vinyl Picket")
        {
            if(fenceMaterial === "Standard Vinyl")
            {
                fenceAttributes = { material: fenceMaterial, height: fenceHeight, color: fenceColor, style: fenceStyle, type: "Picket"};
            }
        }
        
        if(fenceStyle === "Cedar Flat" || fenceStyle === "Cedar Garden" || fenceStyle === "Cedar Round" || fenceStyle === "Cedar Diamond")
        {
            fenceAttributes = { material: "", height: fenceHeight, color: fenceColor, style: fenceStyle, type: fenceType};
        }

        if(fenceStyle === "Vinyl Flat")
        {
            fenceAttributes.style = "Flat";
        }

        if(fenceStyle === "Vinyl Diamond")
        {
            fenceAttributes.style = "Diamond";
        }

        if(fenceType === "Wood Post & Rail" || fenceType === "Vinyl Post & Rail")
        {
            if(wireMesh === "Yes")
            {
                fenceAttributes.type = "Post & Rail with Wire Mesh";
            }else{
                fenceAttributes.type = "Post & Rail";
            }
        }

        passData(fenceAttributes);
        setFenceType(null); setFenceStyle(null); setFenceHeight(null); setFenceMaterial(null);
        goHome();
    }

    function returnClick(source)
    {
        switch(source) 
        {
            case 'fenceType':
                goHome();
                break;

            case 'fenceStyle':
                setFenceType(null);
                break;

            case 'fenceHeight':
                setFenceStyle(null);
                setWireMesh("blank");
                break;

            case 'skipStyle':
                setFenceStyle(null);
                setFenceType(null);
                break;

            case 'skipHeight':
                setFenceHeight(null);
                setFenceStyle(null);
                break;

            case 'fenceMaterial':
                setFenceHeight(null);
                break;

            case 'fenceColor':
                setFenceMaterial(null);
                setFenceHeight(null);
                break;
                
            case 'draw':
                break;

            default:
                break;
        }
    }

    return (
        <div>
            {fenceType === null && (
                <div>
                    <DefaultLayout passData={setFenceType} fenceCount={fenceCount} returnClick={returnClick}/>
                </div>
            )}

            {fenceType !== null && fenceStyle === null &&(
                <div>
                    <StyleComponent passData={setFenceStyle} returnClick={returnClick} fenceType={fenceType} passHeight={setFenceHeight} passMaterial={setFenceMaterial}/>
                </div>
            )}

            {fenceStyle !== null && fenceHeight === null &&(
                <div>
                    <HeightComponent setMesh = {setWireMesh} bypassMat={setFenceMaterial} passData={setFenceHeight} returnClick={returnClick} fenceType={fenceType} fenceStyle={fenceStyle}/>
                </div>
            )}

            {fenceStyle !== null && fenceHeight !== null && fenceMaterial === null &&(
                <div>
                    <MaterialComponent passData={setFenceMaterial} returnClick={returnClick} fenceType={fenceType}/>
                </div>
            )}

            {fenceStyle !== null && fenceHeight !== null && fenceMaterial !== null && wireMesh === null &&(
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h1 style={{color: 'maroon'}}>With Wire Mesh or Without?</h1>
                        <div style={{ textAlign: "center", display: 'flex', justifyContent: 'flex-start' }}>
                            <button className={styles.Button} onClick={() => returnClick("fenceColor")}>Return</button>
                        </div>
                    </div>  
                    <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                        <div style={{textAlign: 'center'}} onClick={() => {setWireMesh("Yes");}}>
                            <h1>With Wire Mesh</h1>
                            <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Flat-3-Rail-Icon-with-Mesh.webp"} alt="Cedar-Flat-3-Rail-Icon-with-Mesh" />
                        </div>
                        <div style={{textAlign: 'center'}} onClick={() => {setWireMesh("No");}}>
                            <h1>No Wire Mesh</h1>
                            <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Flat-3-Rail-Icon.webp"} alt="Cedar-Flat-3-Rail-Icon" />
                        </div>
                    </div>
                </div>
            )}

            {fenceStyle !== null && fenceHeight !== null && fenceMaterial !== null && wireMesh !== null &&(
                <div>
                    <ColorComponent passData={submitFence} returnClick={returnClick} fenceMaterial={fenceMaterial}/>
                </div>
            )}
            
        </div>
    );
}

export default FenceSelect;