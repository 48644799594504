import React from 'react';
import deleteIcon from "../images/deleteIcon.png";

/*
Component to easily display selected fence
->Handles deleteing objects from map as well via props functions.
*/

const CustomFenceComponent = ({ material, height, color, style, type, lines, gates, fenceID, updateFenceId, updateArrayValue, updateArrayValue2}) => 
{

  const handleDeleteIconClick = (fenceID, index, polyline) => 
  {// Call the passed updateArrayValue function with appropriate arguments
    updateArrayValue(fenceID, index, polyline);
  };
  const handleDeleteIconClick2 = (fenceID, index, polyline) => 
  {// Call the passed updateArrayValue function with appropriate arguments
    updateArrayValue2(fenceID, index, polyline);
  };

  const totalFootage = lines.reduce((acc, line) => acc + line, 0);

  return (
  <div id={"fence#"+fenceID} onClick={() => updateFenceId(fenceID)} style ={{border: '3px solid black', marginLeft: '10%', marginRight: '10%', marginBottom: '3%'}}>
    <div style={{borderBottom: '3px solid black'}}>
      <div>
        <h1 style={{ marginTop: '0', marginBottom: '0', textAlign: 'center' }}>Fence #{fenceID}</h1>
        <h4 style={{ marginTop: '0', marginBottom: '2%'}}>{height} {color} {material} {style} {type}</h4>
      </div>
    </div>
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <div style={{ flexBasis: '50%', borderRight: '3px solid black'}}>
        <h2 style={{ marginBottom: '0px', marginTop: '0px'}}>Lines</h2>
        
         <ul style={{ marginTop: '0px', textAlign: 'left', marginBottom: '0px' }}>
          {lines.map((line, index) => (
            line !== 0 && (
              <li key={index}>
                <img
                  alt="deleteIcon"
                  onClick={() => handleDeleteIconClick(fenceID, index)}
                  src={deleteIcon}
                  style={{ horizontalAlign: 'right', verticalAlign: 'middle', height: '15%', width: '15%' }}
                />
                  <strong>{`${fenceID + String.fromCharCode(index + 65)}:`}</strong> {line}'
              </li>
              
            )
          ))}
        </ul>
        <h4 style={{paddingBottom: '0px', marginBottom: '2%', marginTop: '0%'}}><b>Total Ft: </b> {totalFootage}'</h4>
      </div>
      <div style={{flexBasis: '50%'}}>
        <h2 style={{ marginBottom: '0px', marginTop: '0'}}>Gates</h2>
         <ul style={{ marginTop: '0px', textAlign: 'left' }}>
         {gates.map((gate, index) => (
            gate !== 0 && (
              <li key={index}>
                <img
                  alt="deleteIcon2"
                  onClick={() => handleDeleteIconClick2(fenceID, index)}
                  src={deleteIcon}
                  style={{ horizontalAlign: 'right', verticalAlign: 'middle', height: '15%', width: '15%' }}
                />
                  <strong>{`${'G' + (index+1)}:`}</strong> {gate}
              </li>
            )
          ))}
        </ul>
      </div>
    </div>
  </div>
  );
};

export default CustomFenceComponent;
