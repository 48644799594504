import React from 'react';
import styles from "../styles.module.css";
/*
Takes Style Input
*/
const StyleComponent = ({ passData, returnClick, fenceType, passHeight, passMaterial}) => {

  let styleContent;

  switch (fenceType) 
  {
    case "Aluminum":
      styleContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => passData("Liberty")}>
                    <h1>Liberty</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Liberty-Aluminum-Cover-Photo.webp"} alt="Liberty-Aluminum-Cover"/>
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Imperial")}>
                    <h1>Imperial</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Imperial-Aluminum-Cover-Photo.webp"} alt="Imperial-Aluminum-Cover"/>
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Estate")}>
                    <h1>Estate</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Estate-Aluminum-Cover-Photo.webp"} alt="Estate-Aluminum-Cover"/>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => passData("Legacy")}>
                    <h1>Legacy</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Legacy-Aluminum-2-Cover-Photo.webp"} alt="Legacy-Aluminum-2-Cover"/>
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Continental")}>
                    <h1>Continental</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Contiental-Aluminum-Cover-Photo.webp"} alt="Contiental-Aluminum-Cover"/>
                </div>
            </div>
        </div>
      );
      break;
      
    case "Cedar Privacy":
      styleContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => passData("Solid Vertical")}>
                    <h2>Solid Vertical</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Solid-Vertical-Privacy-Fence-Icon.webp"} alt="Solid-Vertical-Privacy-Fence-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Semi Vertical")}>
                    <h2>Semi Vertical</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Semi-Vertical-Privacy-Fence-Icon.webp"} alt="Semi-Vertical-Privacy-Fence-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Closed Picket")}>
                    <h2>Closed Picket Top</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Closed-Picket-Top-Privacy-Fence-Icon.webp"} alt="Closed-Picket-Top-Privacy-Fence-Icon" />
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => passData("Square Topper")}>
                    <h2>Square Lattice Top</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Square-Lattice-Top-Privacy-Fence-Icon.webp"} alt="Square-Lattice-Top-Privacy-Fence-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Diagonal Topper")}>
                    <h2>Diagonal Lattice Top</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Diagonal-Lattice-Top-Privacy-Fence-Icon.webp"} alt="Diagonal-Lattice-Top-Privacy-Fence-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Open Picket")}>
                    <h2>Open Picket Top</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Open-Picket-Top-Privacy-Fence-Icon.webp"} alt="Open-Picket-Top-Privacy-Fence-Icon" />
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => passData("Solid Horizontal")}>
                    <h2>Solid Horizontal</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Solid-Horizontal-Privacy-Fence-Icon.webp"} alt="Solid-Horizontal-Privacy-Fence-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Stockade")}>
                    <h2>Stockade</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Stockade-Vertical-Fence-Icon.webp"} alt="Stockade-Vertical-Fence-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Semi Horizontal")}>
                    <h2>Semi Horizontal</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Semi-Horizontal-Privacy-Fence-Icon.webp"} alt="Semi-Horizontal-Privacy-Fence-Icon" />
                </div>
            </div>
        </div>
      );
      break;
    
    case "Vinyl Privacy":
    styleContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => passData("Solid Vertical")}>
                    <h2>Solid Vertical</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Solid-Vertical-Vinyl-Privacy-Fence-Icon.webp"} alt="Solid-Vertical-Vinyl-Privacy-Fence-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Semi Vertical")}>
                    <h2>Semi Vertical</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Semi-Vertical-Vinyl-Privacy-Fence-Icon.webp"} alt="Semi-Vertical-Vinyl-Privacy-Fence-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Closed Picket")}>
                    <h2>Closed Picket Top</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Closed-Picket-Top-Vinyl-Privacy-Fence-Icon.webp"} alt="Closed-Picket-Top-Vinyl-Privacy-Fence-Icon" />
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => passData("Square Topper")}>
                    <h2>Square Lattice Top</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Square-Lattice-Top-Vinyl-Privacy-Fence-Icon.webp"} alt="Square-Lattice-Top-Vinyl-Privacy-Fence-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Diagonal Topper")}>
                    <h2>Diagonal Lattice Top</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Diagonal-Lattice-Top-Vinyl-Privacy-Fence-Icon.webp"} alt="Diagonal-Lattice-Top-Vinyl-Privacy-Fence-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Open Picket")}>
                    <h2>Open Picket Top</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Open-Picket-Top-Vinyl-Privacy-Fence-Icon.webp"} alt="Open-Picket-Top-Vinyl-Privacy-Fence-Icon" />
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => passData("Solid Horizontal")}>
                    <h2>Solid Horizontal</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Solid-Horizontal-Vinyl-Privacy-Fence-Icon.webp"} alt="Solid-Horizontal-Vinyl-Privacy-Fence-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Semi Horizontal")}>
                    <h2>Semi Horizontal</h2>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Semi-Horizontal-Vinyl-Privacy-Fence-Icon.webp"} alt="Semi-Horizontal-Vinyl-Privacy-Fence-Icon" />
                </div>
            </div>
        </div>
    );
    break;

    case "Wood Post & Rail":
      styleContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => {passData("Cedar Flat");}}>
                    <h1>Cedar Flat Rail</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Flat-3-Rail-Icon.webp"} alt="Cedar-Flat-3-Rail-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => {passData("New York"); passHeight("4'"); passMaterial("Cedar")}}>
                    <h1>New York</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Cross-Rail-Icon.webp"} alt="Cedar-Cross-Rail-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => {passData("Cedar Garden"); passHeight("4'"); passMaterial("Cedar")}}>
                    <h1>Cedar Garden</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Garden-Rail-Icon.webp"} alt="Cedar-Garden-Rail-Icon" />
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => {passData("Rustic Split Rail");}}>
                    <h1>Rustic Split</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Locust-Rustic-Split-Rail-Icon.webp"} alt="Locust-Rustic-Split-Rail-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => {passData("Cedar Round");}}>
                    <h1>Cedar Round</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Round-Post-Rail-Icon.webp"} alt="Cedar-Round-Post-Rail-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => {passData("Cedar Diamond");}}>
                    <h1>Cedar Diamond</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Diamond-Post-Rail-Icon.webp"} alt="Cedar-Diamond-Post-Rail-Icon" />
                </div>
            </div>
        </div>
      );
      break;

    case "Vinyl Post & Rail":
    styleContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => {passData("Vinyl Flat");}}>
                    <h1>Vinyl Flat Rail</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Vinyl-Flat-3-Rail-Icon.webp"} alt="Vinyl-Flat-3-Rail-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => {passData("New York");}}>
                    <h1>New York</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Vinly-New-York-Cross-Rail-Icon.webp"} alt="Vinly-New-York-Cross-Rail-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => {passData("Vinyl Diamond");}}>
                    <h1>Vinyl Diamond</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Vinyl-Diamond-Post-Rail-Icon.webp"} alt="Vinyl-Diamond-Post-Rail-Icon" />
                </div>
            </div>
        </div>
    );
    break;

    case "Cedar Picket":
    styleContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => passData("Colonial")}>
                    <h1>Colonial</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Colonial-Picket-Iocn.webp"} alt="Cedar-Colonial-Picket-ICon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Victorian")}>
                    <h1>Victorian</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Cedar-Victorian-Picket-Icon.webp"} alt="Cedar-Victorian-Picket-Icon" />
                </div>
            </div>
        </div>
    );
    break;

    case "Vinyl Picket":
    styleContent = (
        <div>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div style={{textAlign: 'center'}} onClick={() => passData("Colonial")}>
                    <h1>Colonial</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Vinyl-Colonial-Picket-Icon.webp"} alt="Vinyl-Colonial-Picket-Icon" />
                </div>
                <div style={{textAlign: 'center'}} onClick={() => passData("Victorian")}>
                    <h1>Victorian</h1>
                    <img style={{ padding: "0px 0px 0px 0px", width: '300px', height: '250px'}} src={"https://empirefences.com/wp-content/uploads/2024/02/Vinyl-Victorian-Picket-Icon.webp"} alt="Vinyl-Victorian-Picket-Icon" />
                </div>
            </div>
        </div>
    );
    break;

    case "Deer":
        passData("Hex");
        break;
        
    case "Chainlink":
        passData("Diamond");
        break;

    default:
      styleContent = (
        <div>
          {/* Default segment content */}
        </div>
      );
  }

  return (
    <div style={{ paddingBottom: "80px" }}>
      <div id="fenceTypes">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h1 style={{color: 'maroon'}}>Please Select a {fenceType} Fence Style!</h1>
            <div style={{ textAlign: "center", display: 'flex', justifyContent: 'flex-start' }}>
                <button className={styles.Button} onClick={() => returnClick("fenceStyle")}>Return</button>
            </div>
        </div>
        {styleContent}
      </div>
    </div>
  );
}

export default StyleComponent;
