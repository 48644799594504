import React from 'react';
import { Debounce } from '../components/debounce.js';
/*
Places-Page View; Takes in clients address and uses debounce page to 
properly come up google search suggestions making it easier for address selection.
*/
const PlacesPage = ({ passData }) => 
{
  return (
    <div style={{ backgroundImage: "url('https://empirefences.com/wp-content/uploads/2024/03/backgroundEmpire.webp')", backgroundSize: 'cover', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ textAlign: "center" }}>
        <img style={{ padding: "0px 0px 20px 0px", width: '400px',}} src="https://empirefences.com/wp-content/uploads/2024/07/empireCreatesLogoWhite2.png" alt="Company Logo" />
      </div>
      <div style={{ textAlign: "center", color: "#fff" }}>
        <h2>Please Enter your Address</h2>
      </div>
      <Debounce passData={passData}/>
    </div>
  );
}

export default PlacesPage;
