import { useState, useEffect } from "react";
import { Input, List } from "antd";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

export const Debounce = ({ passData }) => {
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = useGoogle({});
  const [value, setValue] = useState("");
  let locale = { emptyText: ' ' };

  useEffect(() => {
    if (!window.google || !window.google.maps || !window.google.maps.places || !window.google.maps.places.AutocompleteService) {
      console.error('Google Maps AutocompleteService is not available.');
    }
  }, []);

  const handleSelect = async (address) => {
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setValue(address, false);
      passData(address, lat, lng);
    } catch (error) {
      console.error('Error getting geocode or lat/lng:', error);
    }
  };

  return (
    <div style={{ width: "300px", margin: "0 auto", textAlign: "center" }}>
      <Input.Search
        style={{ color: "white" }}
        value={value}
        suffix={null}
        onChange={(evt) => {
          getPlacePredictions({ input: evt.target.value });
          setValue(evt.target.value);
        }}
        loading={isPlacePredictionsLoading}
      />
      <div style={{ marginTop: "20px", width: "300px", height: "200px", display: "flex", flex: "1", flexDirection: "column", marginBottom: "100px", color: "white" }}>
        {!isPlacePredictionsLoading && (
          <List
            dataSource={placePredictions}
            locale={locale}
            renderItem={(item) => (
              <List.Item onClick={() => handleSelect(item.description)}>
                <List.Item.Meta title={<span style={{ color: "white", cursor: 'pointer' }}>{item.description}</span>} />
              </List.Item>
            )}
          />
        )}
      </div>
    </div>
  );
};