import React, { useState } from 'react';
import PlacesComponent from './PlacesPage';
import MapComponent from './MapPage';
import EmailComponent from './InfoPage';
/*
Purpose of this page is to handle transitions of views
->Houses data from a view and passes to next. 
*/
const AppView = () => {

    const [view, setView] = useState('places');         //Used to keep track of page-view
    const [address, setAddress] = useState(null);       //Used to house input address
    const [geoLat, setGeoLat] = useState(null);         //Used to house geo of ^
    const [geoLng, setGeoLng] = useState(null);         //Used to house geo of ^ 
    const [imageData, setImageData] = useState(null);   //Used to house drawn image

    //Functions to properly load the variables above when transitioning views
    function loadMapData(address, lat, lng)
    {
        setAddress(address);
        setGeoLat(lat);
        setGeoLng(lng);
        setView('map');
    }
    function loadImageData(img)
    {
        setImageData(img);
        setView('email');
    }

    return (
        <div>
            {view === 'places' && (
                <div>
                    <PlacesComponent passData={loadMapData}/>
                </div>
            )}
            {view === 'map' && address !== null && geoLat !== null && geoLng !== null &&(
                <div>
                    <MapComponent passData={loadImageData} passGeoLat={geoLat} passGeoLng={geoLng}/>
                </div>
            )}
            {view === 'email' && address !== null && (
                <div>
                    <EmailComponent passAddress={address} passImage={imageData}/>
                </div>
            )}
        </div>
    );
}

export default AppView;